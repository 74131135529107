import * as React from 'react';
import '../components/layout';

import logo from '../images/logo.svg';
import pattern1 from '../images/pattern1.svg';

const IndexPage = () => {
  return (
    <main>
      <div className='flex flex-col container mx-auto px-2 mt-16'>
        <div className='flex h-auto w-full items-center mb-16'>
          <img src={logo} className='h-16 mr-auto' />
          <a className='ml-24 font-bold'>MY WORK</a>
          <a className='ml-24 font-bold'>ABOUT ME</a>
          <a className='ml-24 font-bold'>CONTACT</a>
        </div>
        <div className='mb-8'>
          <h1 className='font-bold text-7xl'>
            Hello! I'm Jaerica Vitug, a Graphic Designer.
          </h1>
        </div>
        <div className='w-auto flex '>
          <div className='self-start flex flex-col items-center h-screen'>
            <div className='h-32 '>
              <h4 className='text-xl vertical-rl transform rotate-180'>
                MY WORK
              </h4>
            </div>
            <img src={pattern1} className='-ml-6' />
          </div>
        </div>
      </div>
    </main>
  );
};

export default IndexPage;
